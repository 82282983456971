import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';
import yaml from 'js-yaml';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CloseRounded, CodeRounded } from '@mui/icons-material';
import Input from '../../Input';
import {
  useCreateVisualMutation,
  useGetVisualDocumentQuery,
  useUpdateVisualMutation,
} from '../../../redux/services/visuals/api';
import { FORMDATA_ASSET, FORMDATA_DEFINITION } from './Modal';
import { VisualResponse } from '../../../redux/services/visuals/types';
import withHidden from '../../../hoc/withHidden';
import { ROUTES_PATH } from '../../../constants';
import { AddPhotoIcon, TrashIcon } from '../../../icons';
import useSnackbar from '../../../hooks/useSnackbar';
import { isForbiddenError } from '../../../redux/utils';

const ModalComponent = styled(Dialog)({
  '& .MuiDialog-paper': {
    flexDirection: 'row',
    overflow: 'inherit',
    alignItems: 'stretch',
    width: '520px',
    maxWidth: '90%',
    minHeight: '200px',
  },
  '& .MuiDialog-backdrop': {
    opacity: 0,
  },
});

const ContentOuter = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const ContentWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  maxHeight: '100%',
  [theme.breakpoints.down('sm')]: {
    overflow: 'auto',
  },
}));

const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

const LeftContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
}));

const ThumbWrap = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  height: '180px',
  maxHeight: '180px',
  flex: 1,
  margin: theme.spacing(2),
  marginLeft: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  position: 'relative',
  '& img': {
    height: 'auto',
    cursor: 'pointer',
    maxHeight: '100%',
  },
  '&.has-image:hover:after': {
    content: '"Change image"',
    position: 'absolute',
    display: 'block',
    padding: '0.5em',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.default,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
}));

const Title = styled(DialogTitle)(({ theme }) => ({
  fontSize: '1.5rem',
  whiteSpace: 'nowrap',
  marginRight: theme.spacing(2),
  padding: 0,
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
}));

const Preview = styled(Avatar)({
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  height: '100%',
  borderRadius: 0,
  border: 0,
  backgroundColor: 'transparent',
  fontSize: '1rem',
  textAlign: 'center',
  lineHeight: '1.5rem',
});

const CloseBtn = styled(IconButton)({
  position: 'absolute',
  right: 16,
  top: 16,
});

interface Props {
  open: boolean;
  onClose: any;
  visual: VisualResponse | undefined;
}

const EditVisualsInEditor = withHidden(
  ({ imageFile, visual }: { imageFile: File | undefined; visual: any }) => {
    const history = useHistory();

    const handleRedirect = () => {
      if (visual?.id) history.push(`${ROUTES_PATH.CREATE_VISUAL}/${visual.id}`);
      // handle current uploaded image
      if (!visual?.id) history.push(`${ROUTES_PATH.CREATE_VISUAL}`);
    };

    return (
      <Box>
        <Button
          fullWidth
          sx={{ mt: 1 }}
          variant="outlined"
          color="primary"
          onClick={handleRedirect}
          startIcon={<CodeRounded />}
        >
          Open in editor
        </Button>
      </Box>
    );
  },
);

const insertDataIntoYAML = (
  text: string,
  { name, description }: { name: string; description: string },
) => {
  try {
    const jsonData = yaml.load(text);
    const jsonDataName = jsonData.name;
    const jsonDataDescription = jsonData.description;
    let newText = text;
    if (name)
      newText = newText.replace(`name: ${jsonDataName}`, `name: ${name}`);
    if (description)
      newText = newText.replace(
        `description: ${jsonDataDescription}`,
        `description: ${description}`,
      );

    return { data: newText, error: undefined };
  } catch (err) {
    console.error('Corrupted file provided');
    return { data: undefined, error: err };
  }
};

const InnerModal = (props: Props) => {
  const { open, onClose, visual } = props;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [definitionFile, setDefinitionFile] = useState<File | undefined>(
    undefined,
  );
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [name, setName] = useState<string | undefined>('');
  const [description, setDescription] = useState<string | undefined>('');

  const [
    createVisual,
    { isSuccess: isCreated, error: createError, data: createdVisual },
  ] = useCreateVisualMutation();
  const [
    updateVisual,
    { isSuccess: isUpdated, error: updateError, data: updatedVisual },
  ] = useUpdateVisualMutation();

  const { showSuccess, showError } = useSnackbar();

  const { data: documentData, isLoading: isDocumentDataLoading } =
    useGetVisualDocumentQuery(visual?.id, {
      skip: !visual,
    });

  useEffect(() => {
    if (open && documentData && visual) {
      const fileData = yaml.load(documentData);
      setName(fileData.name);
      setDescription(fileData.description);

      const blobData = new Blob([documentData], { type: 'text/plain' });
      const defFile = new File([blobData], `${visual.name}.yaml`, {
        type: 'text/yaml',
      });

      setDefinitionFile(defFile);
    }
  }, [documentData, open]);

  useEffect(() => {
    const cleanout = () => {
      setName('');
      setDescription('');
      setImageFile(undefined);
      setDefinitionFile(undefined);
    };

    if (visual === undefined) {
      cleanout();
    }

    return () => {
      cleanout();
    };
  }, [visual, open]);

  const handleAction = async () => {
    if (imageFile || definitionFile) {
      definitionFile
        .text()
        .then((data) => {
          const newTextData = insertDataIntoYAML(data, { name, description });
          if (!newTextData?.error) {
            const formData = new FormData();

            if (imageFile) formData.append(FORMDATA_ASSET, imageFile);
            if (newTextData.data) {
              const definitionFileNew = new Blob([newTextData.data], {
                type: 'text/yaml',
              });
              formData.append(FORMDATA_DEFINITION, definitionFileNew);
            }
            if (!visual) createVisual(formData);
            else updateVisual({ id: visual.id, form: formData });
          } else {
            showError(
              `Failed to create visual: ${
                newTextData?.error?.reason ?? 'corrupted file provided'
              }`,
            );
          }
        })
        .finally(() => {
          onClose();
        });
    }
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (isCreated || isUpdated) {
      setName('');
      setDescription('');
      setImageFile(undefined);
      setDefinitionFile(undefined);
      onClose();
    }
  }, [isCreated, isUpdated]);

  const { getRootProps: getAssetRootProps, getInputProps: getAssetInputProps } =
    useDropzone({
      noKeyboard: true,
      accept: '.svg',
      multiple: false,
      onDrop: async (acceptedFiles) => {
        setImageFile(acceptedFiles[0]);
      },
    });

  const {
    getRootProps: getDefinitionRootProps,
    getInputProps: getDefinitionInputProps,
  } = useDropzone({
    noKeyboard: true,
    accept: '.yaml, .yml',
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setDefinitionFile(acceptedFiles[0]);
    },
  });

  useEffect(() => {
    if (isCreated && createdVisual) {
      showSuccess(`Visual created: ${createdVisual.name}`);
    }
    if (isUpdated && updatedVisual) {
      showSuccess(`Visual updated: ${updatedVisual.name}`);
    }
  }, [isCreated, isUpdated, createdVisual, updateVisual]);

  useEffect(() => {
    if (!createError) return;
    if (isForbiddenError(createError)) {
      showError('You do not have permission to create a visual');
      return;
    }
    showError('Failed to create visual');
  }, [createError]);

  useEffect(() => {
    if (!updateError) return;
    if (isForbiddenError(updateError)) {
      showError('You do not have permission to update a visual');
      return;
    }
    showError('Failed to update visual');
  }, [updateError]);

  const visualImg = visual ? visual?.image : '';
  const isImgAttached = imageFile || visual?.image;
  const isDisableAddButton = !definitionFile || !name || !isImgAttached;
  return (
    <ModalComponent
      aria-labelledby="add-element-title"
      aria-describedby="add-element-description"
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <ContentOuter>
        <ContentWrap
          sx={{
            borderRight: '1px solid',
            borderColor: 'divider',
            flex: '1 1 65%',
            maxHeight: { xs: 'inherit', sm: '100%' },
          }}
        >
          <Head>
            <Title id="add-element-title">
              {visual ? 'Edit element' : 'Add element'}
            </Title>
            {isMobile && (
              <CloseBtn onClick={onClose}>
                <CloseRounded />
              </CloseBtn>
            )}
          </Head>
          <LeftContent>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor="def-file">
                Definition file
              </InputLabel>
              <Box
                sx={{ display: 'flex', mt: 4 }}
                {...getDefinitionRootProps()}
              >
                <input {...getDefinitionInputProps()} />
                <Input
                  sx={{ mt: 0, mr: 1 }}
                  fullWidth
                  id="def-file"
                  placeholder="No file uploaded"
                  readOnly
                  value={definitionFile?.name}
                />
                <Button variant="outlined" color="secondary" component="label">
                  Pick file...
                </Button>
              </Box>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel shrink htmlFor="title">
                Title
              </InputLabel>
              <Input
                id="title"
                value={name}
                onChange={handleName}
                disabled={isDocumentDataLoading}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel shrink htmlFor="description">
                Description
              </InputLabel>
              <Input
                id="description"
                multiline
                rows={5}
                value={description}
                onChange={handleDescription}
                disabled={isDocumentDataLoading}
              />
            </FormControl>
            {isMobile && (
              <FormControl fullWidth>
                <Box {...getAssetRootProps()}>
                  <input {...getAssetInputProps()} />
                  <ThumbWrap
                    className={imageFile || visual?.image ? 'has-image' : ''}
                  >
                    <Preview
                      src={
                        imageFile ? URL.createObjectURL(imageFile) : visualImg
                      }
                      alt="Name"
                    >
                      Click or drag image to upload
                    </Preview>
                  </ThumbWrap>
                  <Box sx={{ pr: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      component="label"
                      startIcon={<AddPhotoIcon />}
                    >
                      Upload image
                    </Button>
                  </Box>
                </Box>
                <Box>
                  {(imageFile || visual?.image) && (
                    <Button
                      fullWidth
                      sx={{ mt: 1 }}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setImageFile(undefined);
                      }}
                      startIcon={<TrashIcon />}
                    >
                      Remove image
                    </Button>
                  )}
                </Box>
                <Box sx={{ pr: { xs: 0, sm: 2 } }}>
                  <EditVisualsInEditor visual={visual} imageFile={imageFile} />
                </Box>
              </FormControl>
            )}
          </LeftContent>
        </ContentWrap>
        {!isMobile && (
          <ContentWrap
            sx={{
              pl: 2,
              flex: '1 1 35%',
              maxHeight: { xs: 'inherit', sm: '100%' },
              width: { xs: '100%', sm: '35%' },
            }}
          >
            <FormControl fullWidth>
              <Box {...getAssetRootProps()}>
                <input {...getAssetInputProps()} />
                <ThumbWrap
                  className={imageFile || visual?.image ? 'has-image' : ''}
                >
                  <Preview
                    src={imageFile ? URL.createObjectURL(imageFile) : visualImg}
                    alt="Name"
                  >
                    Click or drag image to upload
                  </Preview>
                </ThumbWrap>
                <Box sx={{ pr: 2 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    component="label"
                    startIcon={<AddPhotoIcon />}
                  >
                    Upload image
                  </Button>
                </Box>
              </Box>
              <Box sx={{ pr: 2 }}>
                {(imageFile || visual?.image) && (
                  <Button
                    fullWidth
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setImageFile(undefined);
                    }}
                    startIcon={<TrashIcon />}
                  >
                    Remove image
                  </Button>
                )}
              </Box>
              <Box sx={{ pr: 2 }}>
                <EditVisualsInEditor visual={visual} imageFile={imageFile} />
              </Box>
            </FormControl>
            <Actions>
              <Button onClick={onClose} variant="outlined" color="inherit">
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleAction}
                disabled={isDisableAddButton}
              >
                {visual ? 'Update' : 'Add'}
              </Button>
            </Actions>
          </ContentWrap>
        )}
        {isMobile && (
          <Actions>
            <Button onClick={onClose} variant="outlined" color="inherit">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleAction}>
              {visual ? 'Update' : 'Add'}
            </Button>
          </Actions>
        )}
      </ContentOuter>
    </ModalComponent>
  );
};

export default InnerModal;
